import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

type CommunityManagerContextState = Readonly<{
  isShowCreateMessageForm: boolean;
  setIsShowCreateMessageForm: (value: boolean) => void;
  isViewBroadCastPage: boolean;
  setIsViewBroadCastPage: (value: boolean) => void;
}>;

const CommunityManagerContext = createContext<CommunityManagerContextState>({
  isShowCreateMessageForm: false,
  setIsShowCreateMessageForm: () => null,
  isViewBroadCastPage: false,
  setIsViewBroadCastPage: () => null,
});

export const CommunityManagerContextProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const [isShowCreateMessageForm, setIsShowCreateMessageForm] =
    useState<boolean>(false);
  const [isViewBroadCastPage, setIsViewBroadCastPage] =
    useState<boolean>(false);

  return (
    <CommunityManagerContext.Provider
      value={{
        isShowCreateMessageForm,
        setIsShowCreateMessageForm,
        isViewBroadCastPage,
        setIsViewBroadCastPage,
      }}
    >
      {children}
    </CommunityManagerContext.Provider>
  );
};

export const useCm3Context = (): CommunityManagerContextState => {
  return useContext(CommunityManagerContext);
};
