import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import React from 'react';
import { dusk, eggshell, heather, light, midnight } from 'src/constants/colors';
import { ReactComponent as IconDiscord } from '../../assets/icn-discord.svg';
import { ReactComponent as IconNotifi } from '../../assets/icn-notifi.svg';
import { ReactComponent as IconEmail } from '../../assets/icn-email.svg';
import { ReactComponent as IconTelegram } from '../../assets/icn-telegram.svg';
import { ReactComponent as IconSms } from '../../assets/icn-sms.svg';
import { ReactComponent as IconWhatsApp } from '../../assets/icn-whatsapp.svg';
import { ReactComponent as IconSlack } from '../../assets/icn-slack.svg';
import { ReactComponent as WalletIcon } from '../../assets/icn-wallet.svg';
import { ReactComponent as BrowserIcon } from '../../assets/icn-browser.svg';
import { useGetMessageDeliveryStatisticsQuery } from 'src/services/gql/generated';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: dusk,
    fontWeight: 700,
    fontFamily: 'Rota',
    padding: 0,
    paddingBottom: '8px',
    paddingLeft: '16px',
    border: 0,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    color: midnight,
    border: 0,
    lineHeight: 'normal',
    fontFamily: 'Rota',
    fontWeight: 600,
    padding: '7px 16px',
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: eggshell,
  },
}));

const icon = (key: string) => {
  switch (key) {
    case 'Notification Inbox':
      return <IconNotifi style={{ fill: heather, width: 15, height: 15 }} />;
    case 'Email':
      return <IconEmail style={{ fill: heather, width: 15, height: 15 }} />;
    case 'Telegram':
      return <IconTelegram style={{ fill: heather, width: 15, height: 15 }} />;
    case 'SMS':
      return <IconSms style={{ fill: heather, width: 15, height: 15 }} />;
    case 'Discord':
      return <IconDiscord style={{ fill: heather, width: 15, height: 15 }} />;
    case 'WhatsApp':
      return <IconWhatsApp style={{ fill: heather, width: 15, height: 15 }} />;
    case 'SlackChannel':
      return <IconSlack style={{ fill: heather, width: 15, height: 15 }} />;
    case 'Web3':
      return (
        <WalletIcon
          style={{ fill: heather, width: 20, height: 20, marginRight: '-4px' }}
        />
      );
    case 'BrowserPush':
      return <BrowserIcon style={{ width: 15, height: 15 }} />;
    default:
      return null;
  }
};

const DestinationsStatistic: React.FC = () => {
  const { data } = useGetMessageDeliveryStatisticsQuery();
  return (
    <Stack
      flexDirection="column"
      sx={{
        flex: 1,
        borderRadius: '8px',
        p: '16px',
        minHeight: '323px',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
        backgroundColor: `${light} !important`,
      }}
    >
      <Typography
        sx={{
          color: midnight,
          fontSize: '22px',
          fontWeight: '900 !important',
          lineHeight: 'normal',
          fontFamily: 'Rota',
          mb: '21px',
          paddingLeft: '16px',
        }}
      >
        Destinations
      </Typography>

      <TableContainer
        sx={{
          backgroundColor: light,
          boxShadow: 'none !important',
        }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  fontSize: 14,
                  fontWeight: 700,
                },
              }}
            >
              <StyledTableCell align="left">Destination</StyledTableCell>
              <StyledTableCell align="left">Subscribers</StyledTableCell>
              <StyledTableCell align="left">Msgs Sent</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.messageDeliveryStatistics?.messagesByTarget?.stats?.map(
              (row) => {
                if (
                  [
                    'Email',
                    'Sms',
                    'Telegram',
                    'Discord',
                    'SlackChannel',
                    'Web3',
                    'BrowserPush',
                  ].includes(row.key)
                ) {
                  return (
                    <StyledTableRow
                      key={row.key}
                      sx={{
                        '& .MuiTableCell-root': {
                          fontSize: 16,
                          fontWeight: 600,
                        },
                      }}
                    >
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottomLeftRadius: '8px',
                          borderTopLeftRadius: '8px',
                        }}
                      >
                        <Stack
                          sx={{ flexDirection: 'row', alignItems: 'center' }}
                        >
                          {icon(row.key)}
                          <Typography
                            sx={{
                              color: midnight,
                              mb: '-3px',
                              ml: '14px',
                            }}
                          >
                            {row.key === 'SlackChannel'
                              ? 'Slack'
                              : row.key === 'BrowserPush'
                              ? 'Browser'
                              : row.key === 'Web3'
                              ? 'Wallet'
                              : row.key}
                          </Typography>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography sx={{ color: midnight, mb: '-3px' }}>
                          {row.value.users}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sx={{
                          borderBottomRightRadius: '8px',
                          borderTopRightRadius: '8px',
                        }}
                      >
                        <Typography sx={{ color: midnight, mb: '-3px' }}>
                          {row.value.messages}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                }
                return null;
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default DestinationsStatistic;
