import ContentEditor from '@components/content-editor';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { cloud, dusk, eggshell, midnight } from 'src/constants/colors';

type SlackComposerProps = Readonly<{
  browserMessageContent: string;
  setBrowserMessageContent: (browserMessageContent: string) => void;
  isDefineTemplate?: boolean;
  isCommunityManager: boolean;
  browserSubject: string;
  setBrowserSubject: (browserSubject: string) => void;
  selectedTopicKey?: string;
}>;

export const BrowserComposer: React.FC<SlackComposerProps> = ({
  browserMessageContent,
  setBrowserMessageContent,
  isDefineTemplate,
  isCommunityManager,
  browserSubject,
  setBrowserSubject,
}) => {
  return (
    <>
      {isDefineTemplate ? (
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontFamily: 'Rota',
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    mt: isDefineTemplate ? 3 : 2,
                    mr: '5px',
                  }}
                >
                  Browser
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                mt: 2,
              }}
            >
              Subject / Title
            </Typography>
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '12px',
                fontWeight: 800,
                lineHeight: '18px',
                mt: 2,
              }}
            >
              max 50 characters
            </Typography>
          </Stack>
          <TextField
            autoFocus
            color="secondary"
            disabled={false}
            fullWidth
            hiddenLabel
            inputProps={{
              autoComplete: 'off',
              maxLength: 50,
            }}
            onChange={(e) => {
              setBrowserSubject(e.target.value);
            }}
            placeholder="Subject"
            size="small"
            sx={{
              '& input': {
                fontFamily: 'Rota',
                fontWeight: 600,
                color: midnight,
                padding: '13px 20px',
                lineHeight: 'normal',
              },
              '& input::placeholder': {
                color: dusk,
                opacity: 1,
              },
              backgroundColor: eggshell,
            }}
            value={browserSubject}
            variant="outlined"
          />
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
          >
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
              }}
            >
              Plain Text Content
            </Typography>
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '12px',
                fontWeight: 800,
                lineHeight: '18px',
              }}
            >
              max 150 characters
            </Typography>
          </Stack>
          <TextField
            color="secondary"
            disabled={false}
            fullWidth
            hiddenLabel
            id="standard-multiline-static"
            inputProps={{
              py: 1.5,
            }}
            multiline
            onChange={(e) => {
              setBrowserMessageContent(e.target.value);
            }}
            placeholder="Insert your content"
            minRows={5}
            size="small"
            sx={{
              '& fieldset': { borderRadius: '4px' },
              '& textarea': {
                fontFamily: 'Rota',
                fontWeight: 600,
                fontSize: '16px',
              },

              '& .MuiInputBase-root': {
                color: midnight,
              },
              mb: 3,
              backgroundColor: eggshell,
            }}
            value={browserMessageContent}
            variant="outlined"
          />
        </>
      ) : (
        <>
          <Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{
                  fontFamily: 'Rota',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  mt: isDefineTemplate ? 3 : 2,
                  mr: '5px',
                }}
              >
                Browser
              </Typography>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: dusk,
                  fontFamily: 'Rota',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  mt: 2,
                }}
              >
                Subject / Title
              </Typography>
              <Typography
                sx={{
                  color: dusk,
                  fontFamily: 'Rota',
                  fontSize: '12px',
                  fontWeight: 800,
                  lineHeight: '18px',
                  mt: 2,
                }}
              >
                max 50 characters
              </Typography>
            </Stack>
          </Stack>
          <TextField
            autoFocus
            color="secondary"
            disabled={false}
            fullWidth
            hiddenLabel
            inputProps={{
              autoComplete: 'off',
              maxLength: 50,
            }}
            onChange={(e) => {
              setBrowserSubject(e.target.value);
            }}
            placeholder="Subject"
            size="small"
            sx={{
              '& input': {
                fontFamily: 'Rota',
                fontWeight: 600,
                color: midnight,
                padding: '13px 20px',
                lineHeight: 'normal',
              },
              '& input::placeholder': {
                color: dusk,
                opacity: 1,
              },
              backgroundColor: eggshell,
            }}
            value={browserSubject}
            variant="outlined"
          />
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
          >
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
              }}
            >
              Content
            </Typography>
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '12px',
                fontWeight: 800,
                lineHeight: '18px',
              }}
            >
              max 150 characters
            </Typography>
          </Stack>
          <ContentEditor
            setValue={setBrowserMessageContent}
            value={browserMessageContent}
            destination="browser"
            isCommunityManager={isCommunityManager}
            maxLimit={16000}
          />
          <Divider
            sx={{
              borderColor: cloud,
              borderWidth: '1.5px',
            }}
          />
        </>
      )}
    </>
  );
};
