import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent, Divider, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { dusk, eggshell, seaglassDarker, white } from 'src/constants/colors';
import { Footer } from '@components/footer';
import { ReactComponent as CheckIcon } from '../../assets/icn-check-seaglass.svg';
import { PrimaryButton } from '@components/buttons/PrimaryButton';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = [
  {
    title: '1',
    description: 'Create topics for your users to subscribe to',
  },
  {
    title: '2',
    description:
      'Create a UI card. Add your topics and pick the destinations your users can receive alerts to',
  },
  {
    title: '3',
    description:
      'Make sure your topics have templates for the selected destinations',
  },
];

type Props = {
  handleClose: () => void;
  open: boolean;
};

const TemplateLearnMoreDialog = ({ handleClose, open }: Props) => {
  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        fullScreen
        onClose={handleClose}
        open={open}
        sx={{
          '&.MuiPaper-root &.MuiDialog-paper': {
            backgroundColor: 'transparent',
          },
          backgroundColor: '#F3F3FA',
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: '#F3F3FA',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 0,
          }}
        >
          <Stack
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              sx={{
                backgroundColor: white,
                borderRadius: '45px',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                position: 'absolute',
                right: '15%',
                top: '56px',
                '&:hover': {
                  backgroundColor: white,
                },
              }}
              onClick={handleClose}
            >
              <CloseIcon sx={{ color: dusk }} />
            </IconButton>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                mt: 6,
                mb: 3,
                width: '100%',
              }}
            >
              <Typography variant="h1">What is a an alert template?</Typography>
              <Typography
                variant="h3"
                sx={{
                  color: dusk,
                  mt: 3,
                  width: '720px',
                }}
              >
                Your alert templates define what your end users see when they
                receive an alert to their chosen destinations, For each topic
                that you create, you can define what the notification will look
                like.
              </Typography>
            </Stack>
            <Stack
              flexDirection="row"
              sx={{ width: '950px', ml: 5 }}
              justifyContent="center"
              alignItems="start"
              gap={'20px'}
            >
              {steps.map((step, index) => {
                return (
                  <Stack
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    key={index}
                    sx={{ width: index === 0 ? '240px' : '300px' }}
                  >
                    <Box
                      sx={{
                        width: '32px',
                        height: '32px',
                        borderRadius: '25px',
                        backgroundColor: white,
                        mb: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          color: seaglassDarker,
                          fontSize: '22px',
                          fontWeight: 900,
                        }}
                      >
                        {' '}
                        {step.title}
                      </Typography>
                    </Box>
                    <Typography sx={{ textAlign: 'center' }} variant="h2">
                      {step.description}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 5, width: '950px' }}
            >
              <Stack>
                {['Topic 1', 'Topic 2', 'Topic 3'].map((topic, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        width: '204px',
                        height: '57px',
                        backgroundColor: white,
                        fontSize: '18px',
                        fontWeight: 900,
                        borderRadius: '6px',
                        mb: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {topic}
                    </Box>
                  );
                })}
              </Stack>
              <Divider
                sx={{
                  width: '60px',
                  mx: 2,
                  borderColor: seaglassDarker,
                  borderWidth: 2,
                }}
              />
              <Stack
                flexDirection="column"
                justifyContent="center"
                sx={{
                  backgroundColor: white,
                  borderRadius: '6px',
                  px:4,
                  py:2
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Rota-Bold',
                    fontSize: '18px',
                    fontWeight: 900,
                  }}
                >
                  Card 1
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: dusk,
                    mt: 1,
                    mb: '14px',
                  }}
                >
                  Selected topics:
                </Typography>
                <Stack
                  flexDirection="column"
                  alignItems="start"
                >
                  {['Topic 1', 'Topic 2', 'Topic 3'].map((topic, index) => {
                    return (
                      <Stack
                        key={index}
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mb: 1 }}
                      >
                        <CheckIcon />
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            ml: 1,
                          }}
                        >
                          {topic}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: dusk,
                    mt: 1,
                    mb: '14px',
                  }}
                >
                  Card destinations:
                </Typography>
                <Stack
                  flexDirection="column"
                  alignItems="start"
                >
                  {['Notification Inbox', 'Email', 'Discord'].map((topic, index) => {
                    return (
                      <Stack
                        key={index}
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mb: 1 }}
                      >
                        <CheckIcon />
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            ml: 1,
                          }}
                        >
                          {topic}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
              <Divider
                sx={{
                  width: '60px',
                  mx: 2,
                  borderColor: seaglassDarker,
                  borderWidth: 2,
                }}
              />
              <Stack
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: white,
                  borderRadius: '6px',
                  width: '204px',
                  height: '204px',
                  pt: 3,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 900,
                  }}
                >
                  Templates
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: dusk,
                    mt: 1,
                    mb: '14px',
                    mx: '20px',
                  }}
                >
                  Required templates for each topic in card 1:
                </Typography>
                <Stack flexDirection="column" alignItems="start">
                  {['Notification Inbox', 'Email', 'Discord'].map((topic, index) => {
                    return (
                      <Stack
                        key={index}
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mb: 1 }}
                      >
                        <CheckIcon />
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            ml: 1,
                          }}
                        >
                          {topic}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
            <PrimaryButton
              buttonTitle="Got it"
              customSx={{ width: '140px', mt: 5 }}
              onClick={handleClose}
            />
          </Stack>
          <Footer sx={{ background: eggshell }} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TemplateLearnMoreDialog;
