import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Stack,
  SxProps,
  IconButton,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { dusk, white } from 'src/constants/colors';

type Props = {
  disabled?: boolean;
  handleClose?: () => void;
  handleConfirm: () => void;
  open: boolean;
  subText?: string;
  title: string | JSX.Element;
  hideSecondaryAction?: boolean;
  ctaTitle?: string;
  sx?: SxProps;
  titleSx?: SxProps;
  primaryBtnSx?: SxProps;
  showCloseIcon?: boolean;
};

export const ConfirmDialog: React.FC<PropsWithChildren<Props>> = ({
  disabled,
  handleClose,
  handleConfirm,
  open,
  subText,
  title,
  ctaTitle = 'Confirm',
  hideSecondaryAction,
  sx,
  children,
  titleSx,
  primaryBtnSx,
  showCloseIcon = false,
}) => {
  return (
    <Dialog
      fullWidth={false}
      maxWidth="md"
      onClose={handleClose}
      open={open}
      sx={{
        width: '100%',
      }}
    >
      <Stack
        flexDirection="column"
        justifyContent="center"
        sx={{
          px: 3,
          py: 4,
          width: '500px',
          ...sx,
          position: 'relative',
        }}
      >
        <Stack
          width={'100%'}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems="center"
        >
          <DialogTitle
            sx={{
              fontSize: '22px',
              p: 1,
              pt: 0,
              width: showCloseIcon ? '90%' : '100%',
              textAlign: 'center',
              ...titleSx,
            }}
          >
            {title}
          </DialogTitle>
          {showCloseIcon && (
            <IconButton
              sx={{
                position: 'absolute',
                right: '20px',
                top: '32px',
                backgroundColor: white,
                borderRadius: '45px',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '0',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: white,
                },
              }}
              onClick={handleClose}
            >
              <CloseIcon sx={{ color: dusk, paddingBottom: '4px' }} />
            </IconButton>
          )}
        </Stack>
        <DialogContentText
          sx={{
            fontSize: '17px',
            p: 1,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {subText}
        </DialogContentText>
        {children}
        <Stack
          alignItems="center"
          direction="row"
          gap={3}
          sx={{ mt: 3 }}
          justifyContent="center"
        >
          {hideSecondaryAction ? null : (
            <SecondaryButton
              onClick={handleClose}
              customSx={{
                width: '164px',
              }}
              buttonTitle="Cancel"
            />
          )}

          <PrimaryButton
            disabled={disabled}
            onClick={handleConfirm}
            customSx={{
              width: '168px',
              ...primaryBtnSx,
            }}
            buttonTitle={ctaTitle}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};
