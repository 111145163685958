import React from 'react';
import { Box, CircularProgress, Stack, Tab, Tabs } from '@mui/material';
import {
  useCardConfigDataContext,
  useGetSubscriptionConfigData,
} from '@components/cardConfig/CardConfigDataContext';
import UIConfigTab from './UIConfigTab';
import { useGetFusionTopicsData } from '@components/alertManager/TopicsDataContext';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { TenantConfigType } from 'src/services/gql/generated';
import { PageHeaderSection } from '@components/section/Header';
import { useNavigate } from 'react-router-dom';
import { getCardNavigationUrl } from 'src/util/CardDisplayUtils';
import { midnight } from 'src/constants/colors';
import { TABS } from '../AccountSettings/constants';

export const AlertManagerConfigsViewContainer: React.FC = () => {
  const [isClicked, setIsClicked] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { create } = useCardConfigDataContext();
  const { items: topicsData, isLoading } = useGetFusionTopicsData();
  const { items: cardConfigData, isLoading: loadingSubscriptionConfigData } =
    useGetSubscriptionConfigData();

  const handleChange = (_: React.SyntheticEvent, value: number) => {
    switch (value) {
      case TABS.storage.value:
        navigate('/alert/storage');
        break;
      default:
        navigate('/alert');
        break;
    }
  };

  const createConfig = () => {
    setIsClicked(true);
    const configType = TenantConfigType.SUBSCRIPTION_CARD;
    create('', configType).then((result) => {
      if (result.id) {
        const url = getCardNavigationUrl(configType, result.id);
        navigate(url);
      }
    });
  };

  if (isLoading || loadingSubscriptionConfigData)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: '200px',
          width: '100%',
        }}
      >
        <CircularProgress size="50px" thickness={2} />
      </Box>
    );

  return (
    <Stack
      sx={{
        padding: '5%',
        position: 'relative',
      }}
    >
      <PageHeaderSection
        title="Alert Manager"
        subTitle="Welcome to the Notifi Network Admin Portal"
      />
      {cardConfigData && cardConfigData.length > 0 ? (
        <PrimaryButton
          disabled={isClicked}
          buttonTitle="+ UI Card"
          customSx={{
            height: '40px',
            padding: '10px',
            width: '100%',
            maxWidth: '90px',
            position: 'absolute',
            top: 'calc(5% + 80px)',
            right: '5%',
            zIndex: 10,
          }}
          onClick={createConfig}
        />
      ) : null}

      <Box
        sx={{
          borderBottom: '1px solid #B6B8D5',
          mt: 2,
          position: 'relative',
        }}
      >
        <Tabs value={1} onChange={handleChange}>
          <Tab
            disableRipple
            label="Topics"
            className=""
            sx={{
              '&.MuiButtonBase-root': {
                padding: 0,
                minWidth: '40px',
                '&.Mui-selected': {
                  color: midnight,
                },
              },
            }}
          />
          <Tab
            disableRipple
            label="UI Config"
            sx={{
              '&.MuiButtonBase-root': {
                padding: 0,
                ml: 4,
                minWidth: '40px',
                '&.Mui-selected': {
                  color: midnight,
                },
              },
            }}
          />
          <Tab
            disableRipple
            label="Storage"
            sx={{
              '&.MuiButtonBase-root': {
                padding: 0,
                ml: 4,
                minWidth: '40px',
                '&.Mui-selected': {
                  color: midnight,
                },
              },
            }}
          />
        </Tabs>
      </Box>

      <Stack spacing={2} mt="40px">
        <UIConfigTab topicsData={topicsData} cardConfigData={cardConfigData} />
      </Stack>
    </Stack>
  );
};
