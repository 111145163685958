import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { dusk, midnight } from 'src/constants/colors';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import { ReactComponent as IconArrowBack } from '../../assets/arrow-back.svg';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetFusionTopicsData,
  useTopicsDataContext,
} from '@components/alertManager/TopicsDataContext';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { FormMessageBar } from '@components/FormMessageBar';
import CreateCMTopicDialog from '@components/alertManager/CreateCMTopicDialog';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import {
  useCardConfigDataContext,
  useGetSubscriptionConfigData,
} from '@components/cardConfig/CardConfigDataContext';
import {
  FusionEventCategory,
  GenericEventIconHint,
} from 'src/services/gql/generated';
import { GeneralEditTopicView } from './GeneralEditTopicView';
import CreateNonCMTopicDialog from '@components/alertManager/CreateNonCMTopicDialog';
import { Icons } from '../../components/alertManager/CustomIconSelect';

export const EditTopicView: React.FC = () => {
  const {
    items: topics,
    isLoading: isTopicsLoading,
    refetchFusionTopicsData,
  } = useGetFusionTopicsData();
  const { items: configs } = useGetSubscriptionConfigData();
  const { deleteFusionEvent } = useTopicsDataContext();
  const { updateItem } = useCardConfigDataContext();
  const navigate = useNavigate();
  const [isDeleteTopicDialogOpen, setIsDeleteTopicDialogOpen] =
    React.useState<boolean>(false);
  const [isCreateCMTopicDialogOpen, setIsCreateCMTopicDialogOpen] =
    React.useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState<boolean>(false);
  const [
    isCreateNonNotifiHostedTopicDialogOpen,
    setIsCreateNonNotifiHostedTopicDialogOpen,
  ] = React.useState<boolean>(false);

  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const params = useParams();
  const topic = topics.find((item) => item?.id === params.cardId);
  const metaData = JSON.parse(topic?.metadata ?? '{}');
  const topicDisplayName = metaData?.uiConfigOverride?.topicDisplayName ?? '';
  const historyDisplayName =
    metaData?.uiConfigOverride?.historyDisplayName ?? '';
  const customIconUrl = metaData?.uiConfigOverride?.customIconUrl ?? '';
  const [icon, setIcon] = React.useState<GenericEventIconHint | string>(
    metaData?.uiConfigOverride?.icon ?? 'Megaphone',
  );

  React.useEffect(() => {
    if (metaData?.uiConfigOverride?.customIconUrl) {
      setIcon('');
    } else if (metaData?.uiConfigOverride?.icon) {
      setIcon(metaData.uiConfigOverride.icon);
    }
  }, [metaData]);

  const renderIcon = (icon: string) => {
    if (icon && !customIconUrl) {
      const IconEle = Icons[icon];
      return (
        <div
          className="svg-color-heather"
          style={{
            width: '200px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconEle />
        </div>
      );
    } else {
      return (
        <Typography
          sx={{
            color: midnight,
            fontSize: '16px',
            fontWeight: 600,
            width: '200px',
            paddingLeft: '5px',
          }}
        >
          Custom Icon
        </Typography>
      );
    }
  };
  const handleDelete = async () => {
    clearMessageBarState();
    try {
      if (topic && topic.id) {
        setIsDeleteLoading(true);
        setIsDeleteTopicDialogOpen(false);
        await deleteFusionEvent(topic.id);
        const configCards = configs.filter((config) =>
          config.eventTypes.filter((event) => event.name === topic.name),
        );
        if (!configCards) {
          setIsDeleteLoading(false);
          navigate('/alert');
          return;
        }
        configCards.map(async (config) => {
          const index = config.eventTypes.findIndex((event) => {
            return event.name === topic.name;
          });
          if (index !== -1) {
            const newEventTypes = [
              ...config.eventTypes.slice(0, index),
              ...config.eventTypes.slice(index + 1),
            ];
            const newConfigItem = {
              ...config,
              eventTypes: newEventTypes,
            };
            await updateItem(newConfigItem);
          }
        });
        setIsDeleteLoading(false);
        navigate('/alert');
      } else {
        setErrorMessage('Failed to delete a topic, please try again');
      }
    } catch (err) {
      setErrorMessage((err as Error).message);
      setIsDeleteLoading(false);
      return;
    }
    refetchFusionTopicsData();
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100%',
        padding:'5%'
      }}
    >
      <CreateCMTopicDialog
        topicId={topic?.id ?? ''}
        currentTopicName={topic?.name ?? ''}
        isEdit={true}
        open={isCreateCMTopicDialogOpen}
        handleClose={() => {
          setIsCreateCMTopicDialogOpen(false);
          clearMessageBarState();
        }}
        metaData={metaData}
      />
      {isCreateNonNotifiHostedTopicDialogOpen ? (
        <CreateNonCMTopicDialog
          topic={topic}
          isEdit={true}
          open={isCreateNonNotifiHostedTopicDialogOpen}
          handleClose={() => {
            setIsCreateNonNotifiHostedTopicDialogOpen(false);
            clearMessageBarState();
          }}
        />
      ) : null}
      {isDeleteLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: '200px',
            width: '100%',
          }}
        >
          <CircularProgress size="50px" thickness={2} />
        </Box>
      ) : (
        <>
          <Stack
            sx={{ width: '100%', mb: '33px' }}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{ cursor: 'pointer', mr: 2 }}
                onClick={() => {
                  navigate('/alert');
                }}
              >
                <IconArrowBack />
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Rota',
                  fontSize: '30px',
                  fontWeight: 900,
                  lineHeight: '37px',
                }}
              >
                Topic Detail
              </Typography>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={3}>
              {topic?.fusionEventCategory === FusionEventCategory.GENERAL ? (
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 700, color: dusk }}
                >
                  <span style={{ fontSize: '14px' }}>
                    Event Type ID: {topic?.id}
                  </span>
                </Typography>
              ) : null}
              <SecondaryButton
                buttonTitle="Delete"
                onClick={() => setIsDeleteTopicDialogOpen(true)}
              />
            </Stack>
          </Stack>
          <FormMessageBar />
          {isTopicsLoading ? null : topic?.fusionEventCategory ===
            FusionEventCategory.GENERAL ? (
            <GeneralEditTopicView />
          ) : (
            <Box
              sx={{
                backgroundColor: '#FEFEFE',
                width: '100%',
                padding: '24px 40px 28px 40px',
                borderRadius: '8px',
                minWidth: '700px',
                boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
              }}
            >
              <Stack flexDirection="column" sx={{ padding: 0 }}>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 700,
                      borderBottom: '3px solid #E8EBF5',
                      width: '424px',
                      paddingBottom: '5px',
                    }}
                  >
                    Overview
                  </Typography>
                  <PrimaryButton
                    buttonTitle="Edit"
                    customSx={{ height: '40px' }}
                    onClick={() => setIsCreateCMTopicDialogOpen(true)}
                  />
                </Stack>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    width: '424px',
                    marginTop: '10px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography
                    sx={{
                      color: dusk,
                      fontSize: '12px',
                      fontWeight: 800,
                    }}
                  >
                    Topic Name
                  </Typography>
                  <Typography
                    sx={{
                      color: midnight,
                      fontSize: '16px',
                      fontWeight: 600,
                      width: '200px',
                      paddingLeft: '5px',
                    }}
                  >
                    {topic?.name ?? ''}
                  </Typography>
                </Stack>

                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '424px', marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: dusk,
                      fontSize: '12px',
                      fontWeight: 800,
                    }}
                  >
                    Topic Display Title in Card
                  </Typography>
                  <Typography
                    sx={{
                      color: midnight,
                      fontSize: '16px',
                      fontWeight: 600,
                      width: '200px',
                      paddingLeft: '5px',
                    }}
                  >
                    {isTopicsLoading ? null : topicDisplayName}
                  </Typography>{' '}
                </Stack>

                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '424px', marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: dusk,
                      fontSize: '12px',
                      fontWeight: 800,
                    }}
                  >
                    Topic Display Title in Alert
                  </Typography>
                  <Typography
                    sx={{
                      color: midnight,
                      fontSize: '16px',
                      fontWeight: 600,
                      width: '200px',
                      paddingLeft: '5px',
                    }}
                  >
                    {isTopicsLoading ? null : historyDisplayName}
                  </Typography>{' '}
                </Stack>

                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '424px', marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: dusk,
                      fontSize: '12px',
                      fontWeight: 800,
                    }}
                  >
                    Icon
                  </Typography>
                  {renderIcon(icon)}
                </Stack>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '424px', marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: dusk,
                      fontSize: '12px',
                      fontWeight: 800,
                    }}
                  >
                    Topic Type
                  </Typography>
                  <Typography
                    sx={{
                      color: midnight,
                      fontSize: '16px',
                      fontWeight: 600,
                      width: '200px',
                      paddingLeft: '5px',
                    }}
                  >
                    Community Manager Topic
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          )}
        </>
      )}

      <ConfirmDialog
        handleClose={() => setIsDeleteTopicDialogOpen(false)}
        handleConfirm={handleDelete}
        open={isDeleteTopicDialogOpen}
        title="Confirm that you would like to delete this topic"
        subText="Deleting a topic removes the subscription for all users."
        ctaTitle="Delete Topic"
      />
    </Stack>
  );
};
