import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  SxProps,
  Typography,
} from '@mui/material';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import { eggshell, heather, midnight, white } from 'src/constants/colors';
import { ReactComponent as ErrorIcon } from '../assets/error-icon.svg';
import { useFeatureFlag } from './auth/FeatureFlagContext';

type NotifiTooltipProps = {
  tooltipContent: string;
  buttonSx?: SxProps;
  tooltipSx?: SxProps;
  boxSx?: SxProps;
  isDefineTemplate?: boolean;
};

export const NotifiTooltip: React.FC<PropsWithChildren<NotifiTooltipProps>> = ({
  tooltipContent,
  children,
  buttonSx,
  tooltipSx,
  boxSx,
  isDefineTemplate,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [anchorRef, setAnchorRef] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <span key="fees-tooltip">
        {children}
        <IconButton
          ref={setAnchorRef}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
          sx={{ p: 0, ...buttonSx }}
        >
          {isDefineTemplate ? (
            <ErrorIcon />
          ) : (
            <InfoOutlined
              sx={{
                color: heather,
                height: '16px',
                width: '16px',
                '&:hover': {
                  color: isAP2Enabled ? heather : eggshell,
                },
              }}
            />
          )}
        </IconButton>
      </span>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          anchorEl={anchorRef}
          open={open}
          placement="top"
          sx={{ zIndex: 2000 }}
        >
          <Box
            sx={{
              p: '16px',
              width: '225px',
              borderRadius: '8px',
              backgroundColor: white,
              marginBottom: '8px',
              boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
              ...boxSx,
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 14,
                whiteSpace: 'pre-line',
                color: midnight,
                ...tooltipSx,
              }}
            >
              {tooltipContent}
            </Typography>
          </Box>
        </Popper>
      </ClickAwayListener>
    </>
  );
};
