import React from 'react';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { PageHeaderSection } from '@components/section/Header';
import { useNavigate } from 'react-router-dom';
import { midnight } from 'src/constants/colors';
import { TABS } from '../AccountSettings/constants';
import StorageTab from './StorageTab';
import AddKeyDialog from './AddKeyDialog';

export const AlertManageStorageViewContainer: React.FC = () => {
  const navigate = useNavigate();
  const [isFormOpen, setIsFormOpen] = React.useState(false);

  const handleChange = (_: React.SyntheticEvent, value: number) => {
    switch (value) {
      case TABS.uiconfig.value:
        navigate('/alert/configs');
        break;
      default:
        navigate('/alert');
        break;
    }
  };

  return (
    <Stack
      sx={{
        padding: '5%',
        position: 'relative',
      }}
    >
      <PageHeaderSection
        title="Alert Manager"
        subTitle="Welcome to the Notifi Network Admin Portal"
      />
      <PrimaryButton
        buttonTitle="Add Key"
        customSx={{
          padding: '10px',
          width: '100%',
          maxWidth: '90px',
          position: 'absolute',
          top: 'calc(5% + 80px)', 
          right: '5%',
          zIndex: 10,
        }}
        onClick={() => setIsFormOpen(true)}
      />

      <Box
        sx={{
          borderBottom: '1px solid #B6B8D5',
          mt: 2,
          position: 'relative',
        }}
      >
        <Tabs value={2} onChange={handleChange}>
          <Tab
            disableRipple
            label="Topics"
            className=""
            sx={{
              '&.MuiButtonBase-root': {
                padding: 0,
                minWidth: '40px',
                '&.Mui-selected': {
                  color: midnight,
                },
              },
            }}
          />
          <Tab
            disableRipple
            label="UI Config"
            sx={{
              '&.MuiButtonBase-root': {
                padding: 0,
                ml: 4,
                minWidth: '40px',
                '&.Mui-selected': {
                  color: midnight,
                },
              },
            }}
          />
          <Tab
            disableRipple
            label="Storage"
            sx={{
              '&.MuiButtonBase-root': {
                padding: 0,
                ml: 4,
                minWidth: '40px',
                '&.Mui-selected': {
                  color: midnight,
                },
              },
            }}
          />
        </Tabs>
      </Box>

      <Stack spacing={2} mt="40px">
        <StorageTab />
      </Stack>

      <AddKeyDialog
        open={isFormOpen}
        handleClose={() => setIsFormOpen(false)}
      />
    </Stack>
  );
};
