import React, { SyntheticEvent, useState } from 'react';

import {
  Box,
  Button,
  ClickAwayListener,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  cloudLight,
  dusk,
  eggshell,
  heather,
  light,
  midnight,
  seaglassDarker,
} from 'src/constants/colors';
import CreateIcon from '@mui/icons-material/Create';
import { FusionEventCategory } from 'src/services/gql/generated';
import { CalloutTooltip } from '@components/alertManager/CalloutTooltip';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CheckIcon } from '../../assets/icn-check-seaglass.svg';
import { CopyIcon } from '../CopyIcon';
import { SubscriptionCardConfigItemV1 } from '../cardConfig/common/CardConfigItemV1';
import { CustomFusionEventData } from '../../pages/AlertManager/UIConfigTab';
import { EditTopicState } from '../../pages/AlertManager/TopicsTab';
import CreateNonCMTopicDialog from './CreateNonCMTopicDialog';

interface TopicCardProps {
  index: number;
  isNonNotifiHostedTopic?: boolean;
  topic: CustomFusionEventData;
  hasSeenEditParserTooltip: string | null;
  hasSeenDefineTemplateTooltip: string | null;
  isEditParserTooltipOpen: boolean;
  isDefineTemplatesTooltipOpen: boolean;
  editParserTooltipRef: React.RefObject<HTMLDivElement>;
  defineTemplatesTooltipRef: React.RefObject<HTMLDivElement>;
  copyText: string;
  handleEditTopic: (id: string) => void;
  handleEditParser: (
    event: React.MouseEvent<HTMLButtonElement>,
    copyText: string,
  ) => void;
  findCardConfig: (id: string) => SubscriptionCardConfigItemV1[];
  showTopicType: (
    topic: CustomFusionEventData,
  ) =>
    | JSX.Element
    | ''
    | 'Community Manager'
    | 'Sent from my server'
    | 'Off-chain';
  setSelectedTopic: (topic: CustomFusionEventData) => void;
  setIsEditParserTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDefineTemplatesTooltipOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsCreateCMTopicDialogOpen: React.Dispatch<
    React.SetStateAction<EditTopicState>
  >;
}

const TopicCard: React.FC<TopicCardProps> = ({
  index,
  isNonNotifiHostedTopic,
  topic,
  hasSeenEditParserTooltip,
  hasSeenDefineTemplateTooltip,
  isEditParserTooltipOpen,
  isDefineTemplatesTooltipOpen,
  editParserTooltipRef,
  defineTemplatesTooltipRef,
  copyText,
  handleEditTopic,
  handleEditParser,
  findCardConfig,
  showTopicType,
  setIsEditParserTooltipOpen,
  setIsDefineTemplatesTooltipOpen,
  setSelectedTopic,
  setIsCreateCMTopicDialogOpen,
}) => {
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [isCreateNonCMTopicDialogOpen, setIsCreateNonCMTopicDialogOpen] =
    useState<boolean>(false);

  const handleTooltipClose = (
    event: Event | SyntheticEvent<Element, Event>,
  ) => {
    event.stopPropagation();
    setTooltipOpen(false);
  };

  const handleTooltipOpen = (event: Event | SyntheticEvent<Element, Event>) => {
    event.stopPropagation();
    setTooltipOpen(true);
  };

  return (
    <>
      {isCreateNonCMTopicDialogOpen ? (
        <CreateNonCMTopicDialog
          topic={topic}
          isEdit={true}
          isEditNonNotifiHosted={isNonNotifiHostedTopic}
          open={isCreateNonCMTopicDialogOpen}
          handleClose={() => {
            setIsCreateNonCMTopicDialogOpen(false);
          }}
        />
      ) : null}

      <ListItem
        onClick={() => handleEditTopic(topic.id)}
        disablePadding
        key={index}
        sx={{
          backgroundColor: light,
          borderRadius: '8px',
          p: '24px 40px',
          boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
          mb: '23px',
          cursor: 'pointer',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          gap="10px"
          sx={{
            flex: 1,
            '@media screen and (max-width: 1024px)': {
              justifyContent: 'start',
            },
          }}
        >
          <Stack sx={{ gap: '4px' }}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <Typography sx={{ fontSize: '22px', fontWeight: 900 }}>
                {topic.name}
              </Typography>
              <Button
                sx={{
                  display: 'flex',
                  minWidth: 'unset',
                  width: 'max-content',
                  mt: '-5px',
                  p: 0,
                  ml: '4px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    topic?.fusionEventCategory === FusionEventCategory.GENERAL
                  ) {
                    setIsCreateNonCMTopicDialogOpen(true);
                  } else {
                    setIsCreateCMTopicDialogOpen({
                      isVisible: true,
                      topic,
                    });
                  }
                }}
              >
                <CreateIcon
                  sx={{
                    mx: '4px',
                    color: heather,
                    width: '16px',
                    height: '16px',
                  }}
                />
              </Button>
            </Stack>

            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 700,
                color: dusk,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
                verticalAlign: 'middle',
              }}
            >
              {showTopicType(topic)}&nbsp;&nbsp;|&nbsp;&nbsp;
              {(() => {
                const data = findCardConfig(topic.id) ?? [];
                if (data.length)
                  return data?.map((v, i, arr) => (
                    <div key={v.id}>
                      {v.name}{' '}
                      {i !== arr.length - 1 ? (
                        <>&nbsp;&nbsp;|&nbsp;&nbsp;</>
                      ) : null}
                    </div>
                  ));

                return 'No card';
              })()}
            </Typography>

            {/* Event Type Id Text */}
            {topic.fusionEventCategory === FusionEventCategory.GENERAL ? (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  title={
                    <Stack
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ p: '8px' }}
                    >
                      <CheckIcon style={{ fill: dusk }} />
                      <Typography
                        sx={{
                          color: dusk,
                          fontSize: '14px',
                          fontWeight: 700,
                          ml: '4px',
                        }}
                      >
                        Copied
                      </Typography>
                    </Stack>
                  }
                  placement="bottom-end"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={tooltipOpen}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        background: cloudLight,
                        borderRadius: '8px',
                        color: dusk,
                        fontWeight: 800,
                        fontSize: '14px',
                        lineHeight: 'normal',
                        marginTop: '10px !important',
                        padding: '2px 5px 0px 5px',
                      },
                    },
                  }}
                >
                  <Stack flexDirection="row" alignItems="center" gap="5px">
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: dusk,
                        mt: '2px',
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={(e) => handleTooltipOpen(e)}
                    >
                      Event Type ID: {topic.id}
                      <Button
                        sx={{
                          p: 0,
                          m: 0,
                          minWidth: '0px',
                          height: '15px',
                          marginLeft: '5px',
                        }}
                        onClick={(e) => handleTooltipOpen(e)}
                      >
                        <CopyIcon copyText={topic.id ?? ''} color={dusk} />
                      </Button>
                    </Typography>
                  </Stack>
                </Tooltip>
              </ClickAwayListener>
            ) : null}
          </Stack>

          {/* Action Buttons */}
          <Stack
            sx={{
              gap: '16px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              flexBasis: '50%',
              position: 'relative',
              justifyContent: 'end',
              '@media screen and (max-width: 1024px)': {
                justifyContent: 'start',
                flexWrap: 'wrap',
                flexBasis: '100%',
              },
            }}
          >
            {topic.fusionEventCategory === FusionEventCategory.GENERAL ? (
              <>
                {isNonNotifiHostedTopic ? null : (
                  <>
                    {!hasSeenEditParserTooltip && index === 0 ? (
                      <Box ref={editParserTooltipRef}>
                        <CalloutTooltip
                          title="Edit your parser and define your alert templates"
                          subtitle="Your topic was created. Finish configuring these details to continue."
                          buttonText="Got It"
                          isTooltipOpen={isEditParserTooltipOpen}
                          placement={'bottom'}
                          onClick={async () => {
                            localStorage.setItem(
                              'hasSeenEditParserTooltip',
                              JSON.stringify(true),
                            );
                            setIsEditParserTooltipOpen(false);
                          }}
                        >
                          <Button
                            onClick={(e) => handleEditParser(e, copyText)}
                            variant="contained"
                            sx={{
                              textTransform: 'none',
                              position: 'relative',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              minWidth: '120px',
                              height: 'auto',
                              textWrap: 'nowrap',
                              backgroundColor: eggshell,
                              color: midnight,
                              '&:hover': {
                                backgroundColor: eggshell,
                              },
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              Parser
                              <CreateIcon
                                sx={{
                                  ml: 1,
                                  color: seaglassDarker,
                                  width: '18px',
                                  height: '18px',
                                }}
                              />
                            </div>
                          </Button>
                        </CalloutTooltip>
                      </Box>
                    ) : (
                      <Button
                        onClick={(e) => handleEditParser(e, copyText)}
                        variant="contained"
                        sx={{
                          textTransform: 'none',
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minWidth: '120px',
                          height: 'auto',
                          textWrap: 'nowrap',
                          backgroundColor: eggshell,
                          color: midnight,
                          '&:hover': {
                            backgroundColor: eggshell,
                          },
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          Parser
                          <CreateIcon
                            sx={{
                              ml: 1,
                              color: seaglassDarker,
                              width: '18px',
                              height: '18px',
                            }}
                          />
                        </div>
                      </Button>
                    )}
                  </>
                )}
                {!hasSeenDefineTemplateTooltip &&
                hasSeenEditParserTooltip &&
                index === 0 ? (
                  <Box ref={defineTemplatesTooltipRef}>
                    <CalloutTooltip
                      title="Define your alert templates"
                      subtitle="Your topic was created. Finish configuring these details to continue."
                      isTooltipOpen={isDefineTemplatesTooltipOpen}
                      placement={'bottom'}
                      buttonText="Got It"
                      onClick={async () => {
                        localStorage.setItem(
                          'hasSeenDefineTemplateTooltip',
                          JSON.stringify(true),
                        );
                        setIsDefineTemplatesTooltipOpen(false);
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: 'none',
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minWidth: '120px',
                          height: 'auto',
                          textWrap: 'nowrap',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedTopic(topic);
                          localStorage.setItem(
                            'hasSeenDefineTemplateTooltip',
                            JSON.stringify(true),
                          );
                        }}
                      >
                        Define Templates
                      </Button>
                      {/* <Button
                        variant="contained"
                        sx={{
                          textTransform: 'none',
                          position: 'relative',
                          backgroundColor: '#F5F6FB !important',
                          color: midnight,
                          display: 'flex',
                          jusstifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        Parser
                        <CreateIcon
                          sx={{
                            mx: '4px',
                            color: seaglass,
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </Button> */}
                    </CalloutTooltip>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedTopic(topic);
                    }}
                    sx={{
                      textTransform: 'none',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '120px',
                      height: 'auto',
                      textWrap: 'nowrap',
                    }}
                  >
                    Define Templates
                  </Button>
                )}
              </>
            ) : null}

            {!((findCardConfig(topic.id) ?? [])?.length > 0) ? (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate('/alert/configs');
                  localStorage.setItem(
                    'hasSeenConfigUICardTooltip',
                    JSON.stringify(true),
                  );
                }}
                variant="contained"
                sx={{
                  textTransform: 'none',
                  minWidth: '120px',
                  height: 'auto',
                  textWrap: 'nowrap',
                }}
              >
                Configure Card
              </Button>
            ) : null}
          </Stack>
        </Stack>
      </ListItem>
    </>
  );
};

export default TopicCard;
