import { PreviewButton } from '@components/buttons/PreviewButton';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import ContentEditor from '@components/content-editor';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  cloud,
  dusk,
  eggshell,
  midnight,
  midnightDarker,
} from 'src/constants/colors';
import { isEmptyContent } from 'src/pages/Community/CreateMessageForm';
import { MessageVariables } from 'src/pages/Community/MessageService';
import {
  GenericEventIconHint,
  TemplateType,
  WalletBlockchain,
  useFusionPreviewCommunityManagerContentLazyQuery,
  usePreviewCommunityManagerContentLazyQuery,
} from 'src/services/gql/generated';
import PreviewDialog from 'src/pages/Community/PreviewDialog';
import { NotifiTooltip } from '@components/NotifiTooltip';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import CustomIconSelect from '@components/alertManager/CustomIconSelect';

type AlertHistoryComposerProps = Readonly<{
  alertHistoryMessageContent: string;
  setAlertHistoryMessageContent: (alertHistoryMessageContent: string) => void;
  selectedTopicKey?: string;
  alertHistorySubject: string;
  setAlertHistorySubject: (alertHistorySubject: string) => void;
  isDefineTemplate?: boolean;
  iconUrl?: string;
  setIconUrl?: (iconUrl: string) => void;
  customIcon?: GenericEventIconHint | string;
  setCustomIcon?: (customIcon: GenericEventIconHint | string) => void;
  isCommunityManager: boolean;
}>;

export const AlertHistoryComposer: React.FC<AlertHistoryComposerProps> = ({
  alertHistoryMessageContent,
  setAlertHistoryMessageContent,
  selectedTopicKey,
  alertHistorySubject,
  setAlertHistorySubject,
  isDefineTemplate,
  customIcon,
  setCustomIcon,
  isCommunityManager,
  iconUrl,
  setIconUrl,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [alertHistoryTemplate, setAlertHistoryTemplate] = useState<string>('');

  const [getContent] = usePreviewCommunityManagerContentLazyQuery();
  const [getFusionPreviewContent] =
    useFusionPreviewCommunityManagerContentLazyQuery();

  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const timestamp = Math.round(Date.now() / 1000);
  const isEnabled =
    !isEmptyContent(alertHistoryMessageContent) &&
    alertHistorySubject.length > 1 &&
    selectedTopicKey !== '';

  const handleClosePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const handleFusionPreview = async () => {
    clearMessageBarState();
    const variables: MessageVariables[] = [
      {
        key: 'subject',
        value: alertHistorySubject,
      },
      {
        key: 'message',
        value: alertHistoryMessageContent,
      },
    ];
    const queryVariables = {
      fusionCommunityManagerPreviewInput: {
        previewTarget: TemplateType.SMS,
        targetTemplateId: selectedTopicKey ?? '',
        variables,
      },
    };
    const { data, error, loading } = await getFusionPreviewContent({
      variables: queryVariables,
    });
    if (!loading && data) {
      setIsPreviewModalOpen(true);
      const { fusionPreviewCommunityManagerContent } = data;
      setAlertHistoryTemplate(fusionPreviewCommunityManagerContent.content);
    } else if (error) {
      setErrorMessage('There was an error generating a preview. Try again!');
    }
  };

  const handlePreview = async () => {
    clearMessageBarState();
    const variables: MessageVariables[] = [
      {
        key: 'subject',
        value: alertHistorySubject,
      },
      {
        key: 'message',
        value: alertHistoryMessageContent,
      },
    ];
    const queryVariables = {
      previewInput: {
        previewTarget: TemplateType.SMS,
        sourceAddress: selectedTopicKey ?? '',
        timestamp,
        variables,
        walletBlockchain: WalletBlockchain.OFF_CHAIN,
      },
    };
    const { data, error, loading } = await getContent({
      variables: queryVariables,
    });
    if (!loading && data) {
      setIsPreviewModalOpen(true);
      const { previewCommunityManagerContent } = data;
      setAlertHistoryTemplate(previewCommunityManagerContent.content);
    } else if (error) {
      setErrorMessage('There was an error generating a preview. Try again!');
    }
  };
  return (
    <>
      {isDefineTemplate && setIconUrl && setCustomIcon ? (
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontFamily: 'Rota',
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    mt: isDefineTemplate ? 3 : 2,
                    mr: '5px',
                  }}
                >
                  Notification Inbox
                </Typography>
                {isDefineTemplate ? (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 800,
                      color: dusk,
                      mt: 3,
                      ml: 1,
                    }}
                  >
                    Required
                  </Typography>
                ) : (
                  <NotifiTooltip
                    buttonSx={{ mt: 2 }}
                    tooltipContent={
                      'The Notification Inbox is the inbox in the notification card where all of a user’s notifications are shown, so it’s required when you are sending a broadcast from Community Manager. You can specify the other destinations you want to send to.'
                    }
                  />
                )}
              </Stack>
              <Typography
                sx={{
                  color: dusk,
                  fontFamily: 'Rota',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  mt: 2,
                }}
              >
                Select icon
              </Typography>
            </Stack>
            {isDefineTemplate ? null : (
              <PreviewButton
                isDefineTemplate={isDefineTemplate}
                disabled={!isEnabled}
                handlePreview={handlePreview}
              />
            )}
          </Stack>
          <CustomIconSelect
            customIcon={customIcon}
            setCustomIcon={setCustomIcon}
            iconUrl={iconUrl}
            setIconUrl={setIconUrl}
          />
          <Typography
            sx={{
              color: dusk,
              fontFamily: 'Rota',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              mt: 2,
            }}
          >
            Subject
          </Typography>
          <TextField
            autoFocus
            color="secondary"
            disabled={false}
            fullWidth
            hiddenLabel
            inputProps={{
              autoComplete: 'off',
              maxLength: 128,
            }}
            onChange={(e) => {
              setAlertHistorySubject(e.target.value);
              clearMessageBarState();
            }}
            placeholder="Subject"
            size="small"
            sx={{
              ...(isAP2Enabled
                ? {
                    '& input': {
                      fontFamily: 'Rota',
                      fontWeight: 600,
                      color: midnight,
                      padding: '13px 20px',
                      lineHeight: 'normal',
                    },
                    '& input::placeholder': {
                      color: dusk,
                      opacity: 1,
                    },
                    backgroundColor: eggshell,
                  }
                : {
                    '& input': {
                      fontFamily: 'Rota',
                      fontWeight: 600,
                    },
                    backgroundColor: midnightDarker,
                  }),
            }}
            value={alertHistorySubject}
            variant="outlined"
          />
          <Typography
            sx={{
              color: dusk,
              fontFamily: 'Rota',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              mt: 2,
            }}
          >
            {isCommunityManager ? 'Content' : 'HTML Content'}
          </Typography>
          {isCommunityManager ? (
            <ContentEditor
              setValue={setAlertHistoryMessageContent}
              value={alertHistoryMessageContent}
              destination="alertHistory"
              isCommunityManager={isCommunityManager}
            />
          ) : (
            <TextField
              color="secondary"
              disabled={false}
              fullWidth
              hiddenLabel
              id="standard-multiline-static"
              inputProps={{
                py: 1.5,
              }}
              multiline
              onChange={(e) => {
                setAlertHistoryMessageContent(e.target.value);
              }}
              placeholder="Insert your HTML content"
              minRows={5}
              size="small"
              sx={{
                '& fieldset': { borderRadius: '4px' },
                '& textarea': {
                  fontFamily: 'Rota',
                  fontWeight: 600,
                  fontSize: '16px',
                },
                ...(isAP2Enabled
                  ? {
                      '& .MuiInputBase-root': {
                        color: midnight,
                      },
                      mb: 3,
                      backgroundColor: eggshell,
                    }
                  : { mb: 3, backgroundColor: midnightDarker }),
              }}
              value={alertHistoryMessageContent}
              variant="outlined"
            />
          )}
          <PreviewDialog
            destination="alertHistory"
            template={alertHistoryTemplate}
            handleClose={handleClosePreviewModal}
            open={isPreviewModalOpen}
          />
        </>
      ) : (
        <>
          {' '}
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontFamily: 'Rota',
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    mt: isDefineTemplate ? 3 : 2,
                    mr: '5px',
                  }}
                >
                  Notification Inbox
                </Typography>
                {isDefineTemplate ? (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 800,
                      color: dusk,
                      mt: 3,
                      ml: 1,
                    }}
                  >
                    Required
                  </Typography>
                ) : (
                  <NotifiTooltip
                    buttonSx={{ mt: 2 }}
                    tooltipContent={
                      'The Notification Inbox is the inbox in the notification card where all of a user’s notifications are shown, so it’s required when you are sending a broadcast from Community Manager. You can specify the other destinations you want to send to.'
                    }
                  />
                )}
              </Stack>
              <Typography
                sx={{
                  color: dusk,
                  fontFamily: 'Rota',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  mt: 2,
                }}
              >
                Subject
              </Typography>
            </Stack>
            <PreviewButton
              disabled={!isEnabled}
              handlePreview={isAP2Enabled ? handleFusionPreview : handlePreview}
            />
          </Stack>
          <TextField
            autoFocus
            color="secondary"
            disabled={false}
            fullWidth
            hiddenLabel
            inputProps={{
              autoComplete: 'off',
              maxLength: 128,
            }}
            onChange={(e) => {
              setAlertHistorySubject(e.target.value);
              clearMessageBarState();
            }}
            placeholder="Subject"
            size="small"
            sx={{
              ...(isAP2Enabled
                ? {
                    '& input': {
                      fontFamily: 'Rota',
                      fontWeight: 600,
                      color: midnight,
                      padding: '13px 20px',
                      lineHeight: 'normal',
                    },
                    '& input::placeholder': {
                      color: dusk,
                      opacity: 1,
                    },
                    backgroundColor: eggshell,
                  }
                : {
                    '& input': {
                      fontFamily: 'Rota',
                      fontWeight: 600,
                    },
                    backgroundColor: midnightDarker,
                  }),
            }}
            value={alertHistorySubject}
            variant="outlined"
          />
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
          >
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
              }}
            >
              Content
            </Typography>
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '12px',
                fontWeight: 800,
                lineHeight: '18px',
              }}
            >
              max 16K characters
            </Typography>
          </Stack>
          <ContentEditor
            setValue={setAlertHistoryMessageContent}
            value={alertHistoryMessageContent}
            destination="alertHistory"
            isCommunityManager={isCommunityManager}
            maxLimit={16000}
          />
          <Divider
            sx={{
              borderColor: isAP2Enabled ? cloud : 'rgba(255, 255, 255, 0.12)',
              borderWidth: isAP2Enabled ? '1.5px' : '1px',
            }}
          />
          <PreviewDialog
            destination="alertHistory"
            template={alertHistoryTemplate}
            handleClose={handleClosePreviewModal}
            open={isPreviewModalOpen}
          />
        </>
      )}
    </>
  );
};
